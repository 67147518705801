import React from "react"
import "../wdyr"
import { navigate } from "gatsby"
import Speakers from "../components/speakers"
import { isLoggedIn } from "../services/auth"

const SpeakerPage = () => {
  if (typeof window !== "undefined" && !isLoggedIn()) {
    navigate("/")
    return null
  }
  return (
    <>
      <Speakers />
   </>
  )
}

export default SpeakerPage
